import ng from 'angular';
import { AcpDeviceServiceProvider } from 'core';
import {
  ApptentiveCordovaPlugin,
  ApptentiveEngageCustomDataProp
} from 'packages/apptentive-plugin/plugin';

export class AcpApptentivePlugin {
  external_id: string;
  hasPermission: boolean;
  apptentive: ApptentiveCordovaPlugin;

  constructor(
    private $window: ng.IWindowService,
    private webapiResource: nsWebclient.WebapiResourceService,
    private acpDeviceService: AcpDeviceServiceProvider,
    private nsPermissions: nsUtils.NsPermissionsService,
    private $log: ng.ILogService
  ) {
    'ngInject';

    // load permission and create instance
    if (this.acpDeviceService.isCordova()) {
      this.setPermission().then((perm) => {
        this.hasPermission = perm;
        this.apptentive = new ApptentiveCordovaPlugin(
          this.$window.Apptentive,
          this.hasPermission
        );
        this.$log.log('[Apptentive] OAC Permission', perm);
      });
    }
  }

  async setExternalId(): Promise<void> {
    // https://confluence.corp.netspend.com/display/proddev/WebApiV2ApptentiveExternalID
    const getAccountExternalId = this.webapiResource<
      void,
      { external_id: string; internal_id: string }
    >({
      method: 'GET',
      ignoreErrors: true,
      path: '/v2/apptentive/account/external-id'
    });

    this.external_id = (await getAccountExternalId()).external_id;
  }

  async setPermission(): Promise<boolean> {
    return await this.nsPermissions.requestPermission('isApptentiveEnabled');
  }

  errorLog(...args: any) {
    this.$log.error('[Apptentive Error]', ...args);
  }

  async sendEvent(
    eventName: string,
    customData: ApptentiveEngageCustomDataProp = {},
    logging = false
  ): Promise<void> {
    if (
      this.acpDeviceService.isCordova() &&
      this.$window.Apptentive &&
      this.hasPermission
    ) {
      // if external id not set
      if (!this.external_id) {
        await this.setExternalId();
      }

      this.apptentive.sendEvent(
        eventName,
        { ...customData, customer_id: this.external_id },
        logging
      );
    }
  }
}
